<template>
  <div class="mt-2 px-0">
    <v-card tile class="pa-3" flat>
      <v-card-title>
        <span
          style="
            font-size: 18px;
            font-weight: 600;
            line-height: 12px;
            text-align: left;
          "
          >Memebers List
        </span>
        <v-avatar
          color="primary"
          size="36px"
          style="font-size: 14px"
          class="white--text ml-3"
          >{{ employeesList.length || 0 }}</v-avatar
        >
        <v-btn
          color="primary"
          @click="sendInvites"
          :disabled="loading"
          class="mx-3 border-radius-6 custom-data-table-action-btn"
          v-if="invites.length > 0"
          >Invite</v-btn
        >
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          dense
          outlined
          class="mr-4 border-radius-6"
          :style="{ maxWidth: '300px' }"
        ></v-text-field>
        <v-btn
          color="primary"
          dark
          class="px-3 border-radius-6 custom-data-table-action-btn"
          @click="openAddEmployeeDialog"
        >
          <v-icon class="mr-1"> mdi-plus </v-icon>
          Add
        </v-btn>
      </v-card-title>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="employeesList"
        :page.sync="currentPage"
        :search="search"
        :items-per-page="15"
        class="custom-data-table mx-4"
        show-select
        v-model="invites"
      >
        <template v-slot:[`item.name`]="{ item }">
          <v-avatar size="36px" class="mr-3" v-if="item.profile_url">
            <v-img alt="Avatar" :src="item.profile_url"></v-img>
          </v-avatar>
          <v-avatar
            v-else
            size="36px"
            class="mr-3 white--text"
            color="secondary"
          >
            {{ item.intials }}
          </v-avatar>
          {{ item.name }}
        </template>
        <!-- role -->
        <template v-slot:[`item.role_id`]="{ item }">
          {{ item.role.name }}
        </template>

        <template v-slot:[`item.total_project`]="{ item }">
          <v-chip
            color="primary"
            style="
              height: 28px;
              width: 60px; /* Set a fixed width */
              border-radius: 6px;
              text-align: center !important;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 700;
              font-weight: 700;
            "
            label
            class="white--text"
          >
            {{ item.projectIds?.length || 0 }}</v-chip
          >
        </template>
        <template v-slot:[`item.total_task`]="{ item }">
          <v-chip
            color="secondary"
            style="
              height: 28px;
              width: 60px; /* Set a fixed width */
              border-radius: 6px;
              text-align: center !important;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 700;
            "
            label
            class="white--text"
          >
            {{ item.__meta__.assignee_count || 0 }}</v-chip
          >
        </template>
        <template v-slot:[`item.task_completed`]="{ item }">
          <v-chip
            color="green"
            style="
              height: 28px;
              width: 60px; /* Set a fixed width */
              border-radius: 6px;
              text-align: center !important;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 700;
            "
            label
            class="white--text"
          >
            {{ item.__meta__?.task_complied || 0 }}</v-chip
          >
        </template>

        <!-- active -->
        <template v-slot:[`item.active`]="{ item }">
          <v-switch
            v-model="item.active"
            :value="item.active"
            @change="disableEmployeeListing(item)"
            color="green"
            inset
          >
          </v-switch>
        </template>

        <!-- action links -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                @click="empDetails(item.id)"
                class="cursor-pointer mr-3"
                v-bind="attrs"
                v-on="on"
              >
                <EyeIcon :size="20" />
              </span>
            </template>
            <span>View Detail</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                @click="redirect(item.id)"
                class="cursor-pointer mr-3"
                v-bind="attrs"
                v-on="on"
              >
                <CalendarBlankIcon :size="20" />
              </span>
            </template>
            <span>View Planning</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                @click="
                  editItem(item);
                  form_title = 'Edit Employee';
                  form_type_insert = false;
                "
                class="cursor-pointer mr-3"
                v-bind="attrs"
                v-on="on"
              >
                <PencilIcon :size="19" />
              </span>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                @click="deleteItem(item)"
                class="cursor-pointer"
                v-bind="attrs"
                v-on="on"
              >
                <TrashIcon :size="19" />
              </span>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <v-dialog v-model="dialogDelete" max-width="450px">
        <v-card>
          <v-card-title class="headline">Alert</v-card-title>
          <v-card-title class="subtitle-1 mb-4"
            >Are you sure you want to Delete this Employee ?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete"
              >Cancel</v-btn
            >
            <v-btn color="red darken-1" text @click="deleteItemConfirm"
              >Delete</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      color="green accent-4"
      align-baseline="center"
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackbarError"
      :timeout="3000"
      color="deep-orange accent-4"
      align-baseline="center"
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbarError = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <Dialog
      :isOpen="showEmployeeAddEditDialog"
      :save="submit"
      :title="form_title"
      :action="true"
      @close="close"
    >
      <template>
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-text-field
              label="Employee Name
"
              name="name"
              v-model="item.name"
              :error="errors.name != null"
              :error-messages="errors.name"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-text-field
              label="Email"
              name="email"
              v-model="item.email"
              :error="errors.email != null"
              :error-messages="errors.email"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-select
              :items="roles"
              v-model="item.role_id"
              :error="errors.role_id != null"
              :error-messages="errors.role_id"
              item-text="name"
              item-value="role_id"
              label="Role"
              name="role_id"
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" class="py-0 mx-0">
            <v-checkbox
              class="mt-0"
              v-model="invited"
              label="Send Invitation"
            ></v-checkbox>
          </v-col>
        </v-row>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import EyeIcon from "@/assets/icons/EyeIcon.vue";
import PencilIcon from "@/assets/icons/PencilIcon.vue";
import TrashIcon from "@/assets/icons/TrashIcon.vue";
// import Drawer from "@/components/common/basic/Drawer.vue";
import CalendarBlankIcon from "../../assets/icons/CalendarBlankIcon.vue";
import Dialog from "../common/basic/Dialog.vue";

export default {
  data: () => ({
    search: "",
    dialogDelete: false,
    form_type_insert: false,
    form_title: "",
    message: "",
    errors: [],
    invites: [],
    loading: false,
    snackbar: false,
    snackbarError: false,
    text: "hello",
    currentPage: null,
    headers: [
      {
        text: "Members Name",
        align: "start",
        value: "name",
      },
      {
        text: "Role",
        value: "role_id",
      },
      {
        text: "Email",
        value: "email",
      },
      {
        text: "Projects Completed",
        value: "total_project",
      },
      {
        text: "Total Tasks",
        value: "total_task",
      },
      {
        text: "Tasks Completed",
        value: "task_completed",
      },
      {
        text: "Active / Inactive",
        value: "active",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    employees: [],
    item: {
      name: "",
      email: "",
      password: "",
      role_id: "",
    },
    invited: true,
    roles: [],
    showEmployeeAddEditDialog: false,
  }),
  components: {
    EyeIcon,
    PencilIcon,
    TrashIcon,
    Dialog,
    CalendarBlankIcon,
  },
  mounted() {
    this.getEmployeeList();
    this.getRoles();
  },
  computed: {
    ...mapState("employees", [
      "syncEmployees",
      "employeesList",
      "cachesEmpData",
    ]),
    // lastPage() {
    //   return Math.ceil(this.employees.length / 5);
    // }
  },
  watch: {
    currentPage(value) {
      localStorage.setItem("employee_page_no", value);
    },
    syncEmployees() {
      if (this.syncEmployees) {
        this.syncWithClickUp();
      }
    },
    // search() {
    //   this.setSearch({
    //     search: this.search,
    //   });
    // },
  },
  methods: {
    ...mapMutations("employees", [
      "setSyncEmployees",
      "setEmployees",
      "isCachesEmpData",
    ]),
    redirect(employee_id) {
      this.$router.push({
        path: "employees/employees-planning/" + employee_id,
      });
    },
    empDetails(employee_id) {
      this.$router.push({ path: "employees/employee-details/" + employee_id });
    },
    sendInvites() {
      let _self = this;
      _self.loading = true;
      _self.$axios
        .post("/sendInvites", {
          employee_ids: _self.invites.map((i) => i.id),
        })
        .then((res) => {
          _self.loading = false;
          let data = res.data;

          if (data.success) {
            _self.invites = [];
            _self.snackbar = true;
            _self.message = data.message;
          } else {
            _self.snackbarError = true;
            _self.message = data.message;
          }
        });
    },
    disableEmployeeListing(item) {
      let _self = this;
      this.$axios
        .post("/disableEmployeeListing", {
          employee_id: item.id,
        })
        .then((res) => {
          let data = res.data;

          if (!data.success) {
            _self.snackbarError = true;
            _self.message = data.message;
          }
        });
    },
    syncWithClickUp() {
      let _self = this;
      _self.loading = true;

      _self.$axios.post("storeClickUpMembers").then((res) => {
        _self.loading = false;
        let data = res.data;
        if (data.success) {
          _self.getEmployeeList();
          _self.setSyncEmployees({
            syncEmployees: false,
          });
        } else {
          (_self.snackbarError = true), (_self.message = data.message);
        }
      });
    },
    submit() {
      if (this.form_type_insert) {
        this.save();
      } else {
        this.update();
      }
    },
    getRoles() {
      let _self = this;

      this.$axios.post("roles").then((res) => {
        let resdata = res.data;

        if (resdata.success) {
          _self.roles = resdata.roles;
          this.item.role_id = this.roles[0].role_id;
        } else {
          _self.snackbarError = true;
          _self.message = resdata.message;
        }
      });
    },
    getEmployeeList() {
      if (this.cachesEmpData) {
        return;
      }
      let _self = this;
      this.loading = true;
      this.$axios.get("employee").then((res) => {
        this.loading = false;

        if (res.data.success) {
          _self.setEmployees(res.data.employees);
          let defaultPage = localStorage.getItem("employee_page_no");
          _self.currentPage = Number(defaultPage ? defaultPage : 1);
          _self.isCachesEmpData(true);
        } else {
          this.snackbarError = true;
          _self.message = res.data.message;
        }
      });
    },
    getEmployeeDetail(id) {
      this.$axios.get("employee/" + id).then((res) => {
        if (res.data.success) {
          this.item = res.data.employee;
        } else {
          this.snackbarError = true;
          this.message = res.data.message;
        }
      });
    },
    editItem(item) {
      this.form_type_insert = false;
      this.editedIndex = this.employeesList.indexOf(item);
      this.getEmployeeDetail(item.id);
      this.showEmployeeAddEditDialog = true;
      this.errors = [];
    },
    deleteItem(item) {
      this.editedIndex = this.employeesList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      let _self = this;

      this.$axios.delete("/employee/" + this.editedItem.id).then((res) => {
        _self.message = res.data.message;
        _self.snackbar = true;
        _self.editedItem = {};
      });
      this.employeesList.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.item.name = "";
      this.item.email = "";
      this.item.password = "";
      this.item.role_id = "";
      this.showEmployeeAddEditDialog = false;
      this.editedItem = {};
      this.editedIndex = -1;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.editedItem = {};
      this.editedIndex = -1;
    },

    save() {
      let postData = this.item;
      postData.is_invited = this.invited;
      this.$axios.post("/employee", postData).then((res) => {
        let _self = this;
        let data = res.data;

        if (!data.success) {
          _self.errors = [];
          if (data.errors) {
            for (let i = 0; i < data.errors.length; i++) {
              _self.errors[data.errors[i].field] = data.errors[i].message;
            }
          } else {
            _self.snackbarError = true;
            _self.message = data.message;
          }
          _self.showEmployeeAddEditDialog = true;
        } else {
          _self.snackbar = true;
          _self.message = data.message;
          _self.isCachesEmpData(false);
          _self.getEmployeeList();
          _self.close();
        }
      });
    },
    update() {
      this.loading = true;
      this.$axios.put("/employee/" + this.item.id, this.item).then((res) => {
        let _self = this;
        let data = res.data;

        if (!data.success) {
          _self.errors = [];
          if (data.errors) {
            for (let i = 0; i < data.errors.length; i++) {
              _self.errors[data.errors[i].field] = data.errors[i].message;
            }
          }
          _self.showEmployeeAddEditDialog = true;
        } else {
          _self.close();
          _self.employeesList[_self.editedIndex] = _self.item;
          _self.isCachesEmpData(false);
          _self.getEmployeeList();
        }
        _self.loading = false;
      });
    },
    openAddEmployeeDialog() {
      this.showEmployeeAddEditDialog = true;
      this.errors = [];
      this.item.role_id = this.roles[0].role_id;
      this.form_title = "Create Employee";
      this.form_type_insert = true;
    },
    closeAddEmployeeDialog() {
      this.showEmployeeAddEditDialog = false;
      this.item = {};
    },
  },
};
</script>
